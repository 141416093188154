import Form from 'react-bootstrap/Form';

const Checkbox = ({ name, handleChange, isChecked }) => {
    return (
    <>
        <Form.Check inline type="checkbox" value={isChecked} onChange={handleChange} name={name}/>{name}&nbsp;&nbsp; 
    </>
    )
}

export default Checkbox;