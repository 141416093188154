import Checkbox from "./Checkbox";
import Form from 'react-bootstrap/Form';


const Categories = ({ data, handleChange, selectedCategories }) => {
    const { PriceRange, 分區, 租售, 建築面積Range} = selectedCategories
    return (
        <Form className="mb-2">
            {data.map((item, i) => {
                return <Checkbox key={i} name={item} isChecked={PriceRange.includes(item) || 分區.includes(item) || 租售.includes(item) || 建築面積Range.includes(item)} handleChange={handleChange}/>
            })}
        </Form>
    )
}

export default Categories;