import React, { useEffect, useState } from 'react'
import CategoriesList from './components/CategoriesList'
import axios from "axios"
import { Form, Button, Container, ListGroup } from 'react-bootstrap';


function PropertyList() {
  let [properties, setProperties] = useState([])

  // const [isHongKong, setIsHongKong] = useState(false);

  const categories = {
    PriceRange: ['200萬以下', '200萬-400萬', '400萬-800萬', '800萬-2000萬', '2000萬以上'],
    租售: ['租','售'],
    建築面積Range: ['300呎以下','300-500呎','500-1000呎','1000-2000呎','2000呎以上'],
    分區: ['長沙灣', '九龍灣', '牛頭角', '觀塘', '藍田', '油塘', '將軍澳', '粉嶺', '坪石']
  }

  const [selectedCategories, setSelectedCategories] = useState({ PriceRange: [],租售: [],建築面積Range: [], 分區: [] },)


  const handleChange = (e) => {
    const checked = e.target.checked
    const filterName = e.target.name
    const { PriceRange, 分區, 租售, 建築面積Range} = categories
    if (checked) {
      if (PriceRange.includes(filterName)) {
        setSelectedCategories({ ...selectedCategories, PriceRange: [...selectedCategories.PriceRange, filterName] })
      }
      if (分區.includes(filterName)) {
        setSelectedCategories({ ...selectedCategories, 分區: [...selectedCategories.分區, filterName] })
      }
      if (租售.includes(filterName)) {
        setSelectedCategories({ ...selectedCategories, 租售: [...selectedCategories.租售, filterName] })
      }
      if (建築面積Range.includes(filterName)) {
        setSelectedCategories({ ...selectedCategories, 建築面積Range: [...selectedCategories.建築面積Range, filterName] })
      }
    } else {
      if (PriceRange.includes(filterName)) {
        setSelectedCategories({
          ...selectedCategories,
          PriceRange: selectedCategories.PriceRange.filter((item) => item !== filterName)
        })
      }
      if (分區.includes(filterName)) {
        setSelectedCategories({
          ...selectedCategories,
          分區: selectedCategories.分區.filter((item) => item !== filterName)
        })
      }
      if (租售.includes(filterName)) {
        setSelectedCategories({
          ...selectedCategories,
          租售: selectedCategories.租售.filter((item) => item !== filterName)
        })
      }
      if (建築面積Range.includes(filterName)) {
        setSelectedCategories({
          ...selectedCategories,
          建築面積Range: selectedCategories.建築面積Range.filter((item) => item !== filterName)
        })
      }
    }
  } 



  const [searchProperty, setSearchProperty] = useState('')

  const URL = 'https://api-server-jwt-secure.herokuapp.com/wellington'
  const KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFjY291bnRAdGhlbWV0YWdhaW4uY29tIiwicGFzc3dvcmQiOiJNZXRBZ2FpbiMwOTAxIiwiaWF0IjoxNjY0OTA0NjMyLCJleHAiOjg2NDAxNjY0ODE4MjMyfQ.jCJo1a2jQeUkqFbmnexgbYYvtcl0qwk-TcGxb9YrGBM'

  const generateLink = (PriceRange = [], 分區 = [], 租售 = [], 建築面積Range = []) => {
    const template = (slug, column) => {
      return `${slug}`
    }

    const generatePriceRangeFilter = (array, column) => {
      return array
        .map((item) => {
          return template(item, column)
        })
        .join('&&PriceRange=')
    }

    const generate分區Filter = (array, column) => {
      return array
        .map((item) => {
          return template(item, column)
        })
        .join('&&分區=')
    }

    const generate租售Filter = (array, column) => {
      return array
        .map((item) => {
          return template(item, column)
        })
        .join('&&租售=')
    }

    const generate建築面積RangeFilter = (array, column) => {
      return array
        .map((item) => {
          return template(item, column)
        })
        .join('&&建築面積Range=')
    }



    let PriceRangeFilter
    let 分區Filter
    let 租售Filter
    let 建築面積RangeFilter
    let link
    let filter

    if (PriceRange.length) {
      PriceRangeFilter = `${generatePriceRangeFilter(PriceRange, 'PriceRange')}`
      filter = `?PriceRange=${PriceRangeFilter}`
    }

    if (分區.length) {
      分區Filter = `${generate分區Filter(分區, '分區')}`
      filter = `?分區=${分區Filter}`
    }

    if (租售.length) {
      租售Filter = `${generate租售Filter(租售, '租售')}`
      filter = `?租售=${租售Filter}`
    }

    if (建築面積Range.length) {
      建築面積RangeFilter = `${generate建築面積RangeFilter(建築面積Range, '建築面積Range')}`
      filter = `?建築面積Range=${建築面積RangeFilter}`
    }

    if (PriceRange.length && 分區.length) {
      filter = `?PriceRange=${PriceRangeFilter}&&分區=${分區Filter}`
    }

    if (分區.length && 建築面積Range.length) {
      filter = `?分區=${分區Filter}&&建築面積Range=${建築面積RangeFilter}`
    }

    if (PriceRange.length && 建築面積Range.length) {
      filter = `?PriceRange=${PriceRangeFilter}&&建築面積Range=${建築面積RangeFilter}`
    }

    if (PriceRange.length && 分區.length && 建築面積Range.length) {
      filter = `?PriceRange=${PriceRangeFilter}&&分區=${分區Filter}&&建築面積Range=${建築面積RangeFilter}`
    }


    link = `${URL}${filter}`

    if (!PriceRange.length && !分區.length && !租售.length && !建築面積Range.length) {
      link = `${URL}`
    }

    return link
  }

  useEffect(() => {
    const { PriceRange, 分區, 租售, 建築面積Range } = selectedCategories
    const link = generateLink(PriceRange, 分區, 租售, 建築面積Range)
    const headers = {
      headers: {
        Authorization: `Bearer ${KEY}`
      }
    }

    const fetchPropertys = async () => {
      try {
        const { data } = await axios.get(`${link}`, headers)
        setProperties(data)

      } catch {

        console.log('Error Fetching Propertys')
      }
    }
    fetchPropertys()

  }, [selectedCategories])

  const resetFilter = () => {
    setSearchProperty("");

  };

  console.log(selectedCategories)


  return (
    <Container className='pb-5'>
      <div>
        <div className='filterSection p-4'>
        <div className='mb-3'>
        <CategoriesList categories={categories} handleChange={handleChange} selectedCategories={selectedCategories} />

        </div>


        <Form className='searchBar'>
        <Form.Control className='searchBarWidth' type="text" placeholder='搜尋關鍵字...' value={searchProperty} onChange={(e) => { setSearchProperty(e.target.value); }} />
        <Button type="button" className="btn resetBtn" onClick={resetFilter}>重設搜尋</Button>
        </Form>

        </div>

        <br></br>
        <h6 className='mb-2'>搜尋結果：</h6>
        <ListGroup>
          {
            properties.filter((property) => {
              if (searchProperty === "") {
                return property
              } else if (property.屋苑.toLowerCase().includes(searchProperty.toLowerCase())) {
                return property
              }
              else if (property.屋苑.toLowerCase().includes(searchProperty.toLowerCase())) {
                return property
              }
              return false

            }).map((property) => {
              return (
                <ListGroup.Item key={property.編號} className='p-4 mb-4 itemlist'>
                  <div className="row">
                  <div className='col-12'>
                  {/* <Link style={{ textDecoration: "none" }}  to={'/' + property.編號}> */}
                  <h4 className="propertyTitle">{property.屋苑}</h4>
                  {/* </Link> */}
                  <div className='mb-1'>
                  <span>{property.分區} | {property.類別} | ID：{property.編號} </span><br></br>

                  {property.期數 && <span>{property.期數}(期)</span>}
                  {property.座數 && <span>{property.座數}(座)</span>}
                  {property.樓層 && <span>{property.樓層}(樓)</span>}
                  {property.單位 && <span>{property.單位}(室)</span>}
                  {property.街道 && <span> | {property.街道}</span>}
                  {property.街號 && <span> | {property.街號}</span>} <br></br>
                  </div>
                  <div className='mb-3'>
                  {property.盤源 && <b><span className="tag">{property.盤源}</span></b>}
                  {property.景觀 && <span className="tag">{property.景觀}</span>}
                  {property.窗台 && <span className="tag">{property.窗台}窗台</span>}
                  {property.花園 && <span className="tag">{property.花園}花園</span>}
                  {property.露台 && <span className="tag">{property.露台}露台</span>}
                  {property.平台 && <span className="tag">{property.平台}平台</span>}
                  {property.天台 && <span className="tag">{property.天台}天台</span>}
                  {property.裝修 && <span className="tag">{property.裝修}</span>}
                  {property.間格 && <span className="tag">{property.間格}</span>}
                  {property.座向 && <span className="tag">{property.座向}</span>}

                  {property.交吉 && <span className="tag">交吉：{property.交吉}</span>}
                  {property.入伙年份 && <span className="tag">入伙年份：{property.入伙年份}</span>}
                  {property.車位數量 && <span className="tag">車位數量：{property.車位數量}</span>}
                  {property.車位號碼 && <span className="tag">車位號碼：{property.車位號碼}</span>}
                  {property.管理費 && <span className="tag">管理費：{property.管理費}</span>}
                  {property.鎖匙密碼 && <span className="tag">鎖匙／密碼：{property.鎖匙密碼}</span>}
                  </div>

                  {property.聯絡1 && <span>聯絡1：{property.聯絡1}<br></br></span>} 
                  {property.聯絡2 && <span>聯絡2：{property.聯絡2}<br></br></span>} 
                  {property.備註 && <span>備註：{property.備註}<br></br></span>}
                  {property.丈量約份地段 && <span>丈量約份地段：{property.丈量約份地段}</span>}                  


                  </div>
                  </div>
                  <hr></hr>
                  <div className='mb-3'>
                  <span>建築：{property.建築面積}呎 @ {property.售價呎建}元</span><br></br>
                  <span>實用：{property.實用面積}呎 @ {property.售價呎實}元</span>
                  </div>
                  <div className="row">
                  <div>
                  {property.售價萬 && <span className="tag price">售：{property.售價萬} 萬</span>}
                  {property.租價 && <span className="tag price">租：{property.租價}@{property.租價呎}</span>}

                  </div>
                  </div>



                </ListGroup.Item>
              )
            })}
        </ListGroup>

      </div>
    </Container>
  )
}

export default PropertyList

