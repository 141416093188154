import Categories from './Categories'

const CategoriesList =({categories, handleChange, selectedCategories, setSelectedCategories}) => {


  return (
    <div>

        <Categories data={categories.分區} handleChange={handleChange} selectedCategories={selectedCategories}/> 

        <Categories data={categories.PriceRange} handleChange={handleChange} selectedCategories={selectedCategories}/>

        <Categories data={categories.建築面積Range} handleChange={handleChange} selectedCategories={selectedCategories}/>



    </div>
  )
}
export default CategoriesList;
