import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const Header = ({ logoutUser, setLogoutUser }) => {
  const [login, setLogin] = useState("");

  useEffect(() => {
    hydrateStateWithLocalStorage();
  }, [logoutUser]);

  const logout = () => {
    localStorage.removeItem("login");
    setLogoutUser(true);
  };

  const hydrateStateWithLocalStorage = () => {
    if (localStorage.hasOwnProperty("login")) {
      let value = localStorage.getItem("login");
      try {
        value = JSON.parse(value);
        setLogin(value); 
      } catch (e) {
        setLogin("");
      }
    }
  };
  return (
    <div>
      <header>
        {!logoutUser && login && login.userLogin ? (
          <Link to="/login">
            <Button className="logoutBtn" onClick={logout}>登出</Button>
          </Link>
        ) : (
          <Link className='C21' to="/"><img src={process.env.PUBLIC_URL+'/C21.svg'} alt="C21Logo" width="90" /></Link>
        )}
      </header>
    </div>
  );
};

export default Header;
