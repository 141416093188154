import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

function NavBar() {
    return (
        <>
            <Navbar className='navBar'>
                <Container>
                <Link className='pt-3 pb-3' to="/"><img src={process.env.PUBLIC_URL+'/logo.svg'} alt="Logo" width="220" /></Link>

                </Container>
                
            </Navbar>
        </>
    );
}

export default NavBar; 