import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axiox from "axios";
import {Stack, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";


const Login = ({ setLogoutUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  let history = useHistory();

  const login = (e) => {
    e.preventDefault();
    axiox
      .post("https://api-server-jwt-secure.herokuapp.com/api/auth/login", {
        email,
        password,
      })
      .then((response) => {
        console.log("response", response);
        localStorage.setItem(
          "login",
          JSON.stringify({
            userLogin: true,
            token: response.data.access_token,
          })
        );
        setError("");
        setEmail("");
        setPassword("");
        setLogoutUser(false);
        history.push("/");
      })
      .catch((error) => setError(error.response.data.message));
  };
  return (
    <Stack gap={3} className="mt-5 col-md-6 mx-auto px-4">
      <h3 style={{textAlign:"center"}}>樓盤資料庫</h3>
      {error && <p style={{ color: "#ac904d", textAlign:"center" }}>{error}</p>}
      <Link className='C21' to="/"><img src={process.env.PUBLIC_URL+'/C21.svg'} alt="C21Logo" width="90" /></Link>

      <Form onSubmit={login}>
      <Form.Group className="mb-3">
      <Form.Control id="username" type="text" value={email} placeholder='世紀21家是居員工帳號' onChange={(e) => setEmail(e.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3">
      <Form.Control id="password" type="password" value={password} placeholder='密碼' onChange={(e) => setPassword(e.target.value)}/>
      </Form.Group>
      <Button className="loginBtn" style={{ width: "100%" }} type="submit">登入</Button>
      </Form>
    </Stack>
 
  );
};

export default Login;
