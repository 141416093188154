import React from "react";
import PropertyList from './PropertyList';
import Login from "./Login";
import { useState } from "react";

const Home = () => {
  const isLoginTrue = JSON.parse(localStorage.getItem("login"));

  const [logoutUser, setLogoutUser] = useState(true);
  return (
    <div>
      {isLoginTrue && isLoginTrue.userLogin ? (
        <PropertyList />
      ) : (
        <Login setLogoutUser={setLogoutUser} />
      )}
    </div>
  );
};

export default Home; 
